import React from "react";
import { TextField, Grid, IconButton } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";

const stepEditor = (props) => (
  <Grid container item direction="row" spacing={2}>
    <Grid item xs={props.disabled ? 12 : 11}>
      <TextField
        onChange={(event) => props.changed("steps", props.index, event)}
        helperText={props.disabled ? null : "Step " + (props.index + 1)}
        value={props.value}
        disabled={props.disabled}
        fullWidth
        multiline
      />
    </Grid>
    {props.disabled ? null : (
      <Grid item xs={1}>
        <IconButton onClick={() => props.removed("steps", props.index)}>
          <RemoveIcon />
        </IconButton>
      </Grid>
    )}
  </Grid>
);

export default stepEditor;
