import React from "react";
import Grid from "@material-ui/core/Grid";
import IngredientEditor from "./IngredientEditor/IngredientEditor";

const ingredientsEditor = (props) => {
  const { data, ...rest } = props;
  return (
    <Grid container direction="column" alignItems="stretch" justify="center">
      {props.data.map((entry, index) => {
        return (
          <Grid item key={index}>
            <IngredientEditor entry={entry} index={index} {...rest} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ingredientsEditor;
