import * as actionTypes from "../actions/types";
import { cloneDeep } from "lodash";

const initialState = {
  recipe: {
    title: "",
    ingredients: [],
    steps: [],
  },
  recipeId: null,
  loading: false,
  readOnly: false,
};

const recipeEditorInit = (state, action) => {
  return { ...state, loading: true };
};

const recipeEditorNew = (state, action) => {
  return cloneDeep(initialState);
};

const recipeEditorEditField = (state, action) => {
  const recipe = { ...state.recipe };
  let section,
    field = null;
  switch (action.key) {
    case "title":
      recipe[action.key] = action.value;
      break;
    case "steps":
      section = [...recipe[action.key]];
      section[action.index] = action.value;
      recipe[action.key] = section;
      break;
    case "ingredients":
      const [index, elem] = action.index;
      section = [...recipe[action.key]];
      field = { ...section[index] };
      field[elem] = action.value;
      section[index] = field;
      recipe[action.key] = section;
      break;
    default:
      break;
  }
  return { ...state, recipe: recipe };
};

const recipeEditorAddSectionEntry = (state, action) => {
  const recipe = { ...state.recipe };
  recipe[action.key] = [...recipe[action.key]];
  switch (action.key) {
    case "steps":
      recipe[action.key].push("");
      break;
    case "ingredients":
      recipe[action.key].push({ type: "", quantity: "", unit: "" });
      break;
    default:
      break;
  }
  return { ...state, recipe: recipe };
};

const recipeEditorDeleteSectionEntry = (state, action) => {
  const recipe = { ...state.recipe };
  const section = [...recipe[action.key]];
  section.splice(action.index, 1);
  recipe[action.key] = section;
  return { ...state, recipe: recipe };
};

const recipeEditorEnableEdit = (state, action) => {
  return { ...state, readOnly: false };
};

const recipeEditorViewRecipe = (state, action) => {
  const recipe = {
    title: action.recipe.title ? action.recipe.title : "",
    steps: action.recipe.steps ? action.recipe.steps : [],
    ingredients: action.recipe.ingredients ? action.recipe.ingredients : [],
  };
  return {
    ...state,
    readOnly: true,
    loading: false,
    recipeId: action.recipeId,
    recipe: recipe,
  };
};

const recipeEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RECIPE_EDITOR_INIT:
      return recipeEditorInit(state, action);
    case actionTypes.RECIPE_EDITOR_NEW:
      return recipeEditorNew(state, action);
    case actionTypes.RECIPE_EDITOR_EDIT_FIELD:
      return recipeEditorEditField(state, action);
    case actionTypes.RECIPE_EDITOR_ADD_SECTION_ENTRY:
      return recipeEditorAddSectionEntry(state, action);
    case actionTypes.RECIPE_EDITOR_DEL_SECTION_ENTRY:
      return recipeEditorDeleteSectionEntry(state, action);
    case actionTypes.RECIPE_EDITOR_VIEW:
      return recipeEditorViewRecipe(state, action);
    case actionTypes.RECIPE_EDITOR_ENABLE_EDIT:
      return recipeEditorEnableEdit(state, action);
    default:
      return state;
  }
};

export default recipeEditorReducer;
