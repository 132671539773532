import { dataApi } from "../../axios";
import * as actionTypes from "./types";

const fetchRecipesStart = () => {
  return { type: actionTypes.FETCH_RECIPES_START };
};

const fetchRecipesSuccess = (data) => {
  const recipes = Object.keys(data ? data : []).map((key) => {
    return { id: key, title: data[key].title };
  });
  return { type: actionTypes.FETCH_RECIPES_SUCCESS, recipes: recipes };
};

const fetchRecipesFail = () => {
  return { type: actionTypes.FETCH_RECIPES_FAIL };
};

export const fetchRecipes = (token, userId) => {
  return (dispatch) => {
    dispatch(fetchRecipesStart());

    dataApi
      .get(`/recipes/${userId}.json?auth=${token}`)
      .then((response) => {
        dispatch(fetchRecipesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchRecipesFail());
      });
  };
};
