import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchRecipes } from "../../store/actions/recipes";
import { setPageTitle } from "../../store/actions/page";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";

class RecipeTable extends Component {
  componentDidMount = () => {
    this.props.onChangePageTitle("Recipes");
    this.props.onFetchRecipes(this.props.token, this.props.userId);
  };

  selectedHandler = (identifier) => {
    this.props.history.push(`/recipes/edit/${identifier}`);
  };

  render() {
    if (this.props.loading) return <CircularProgress />;
    return (
      <TableContainer>
        <Table size="small">
          <TableBody>
            {this.props.recipes
              .sort((a, b) => a.title.localeCompare(b.title))
              .map((row) => (
                <TableRow
                  hover
                  key={row.id}
                  onClick={() => this.selectedHandler(row.id)}
                >
                  <TableCell>{row.title}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.recipes.loading,
    recipes: state.recipes.data,
    token: state.auth.token,
    userId: state.auth.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangePageTitle: (title) => dispatch(setPageTitle(title)),
    onFetchRecipes: (token, userId) => dispatch(fetchRecipes(token, userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipeTable);
