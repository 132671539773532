import * as actionTypes from "./types";
import axios from "axios";

const FIREBASE_KEY = "AIzaSyCtnzsg-yyqolflQGLlgxR4g3_umUgGs7g";

const authStart = () => {
  return { type: actionTypes.AUTH_START };
};

const authSuccess = (token, userId) => {
  localStorage.setItem("token", token);
  localStorage.setItem("userId", userId);

  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    userId: userId,
  };
};

const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

const authRefresh = (refreshToken, expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      const authData = {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      };
      axios
        .post(
          "https://securetoken.googleapis.com/v1/token?key=" + FIREBASE_KEY,
          authData
        )
        .then((response) => {
          localStorage.setItem("token", response.data.id_token);
          localStorage.setItem("userId", response.data.user_id);
          localStorage.setItem("refreshToken", response.data.refresh_token);
          dispatch(authSuccess(response.data.id_token, response.data.user_id));
          dispatch(
            authRefresh(response.data.refresh_token, response.data.expires_in)
          );
        })
        .catch(dispatch(authLogout()));
    }, expirationTime * 1000);
  };
};

export const authLogout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("userId");
  localStorage.removeItem("refreshToken");
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const auth = (email, password, isSignup) => {
  return (dispatch) => {
    dispatch(authStart());

    const authData = {
      email: email,
      password: password,
      returnSecureToken: true,
    };
    const params = "?key=" + FIREBASE_KEY;
    const url = isSignup
      ? "https://identitytoolkit.googleapis.com/v1/accounts:signUp"
      : "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword";

    axios
      .post(url + params, authData)
      .then((response) => {
        localStorage.setItem("token", response.data.idToken);
        localStorage.setItem("userId", response.data.localId);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        dispatch(authSuccess(response.data.idToken, response.data.localId));
        dispatch(
          authRefresh(response.data.refreshToken, response.data.expiresIn)
        );
      })
      .catch((err) => {
        dispatch(authFail(err.response.data.error.message));
      });
  };
};

export const autoLogin = () => {
  return (dispatch) => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (refreshToken) {
      dispatch(authRefresh(refreshToken, 0));
    } else {
      dispatch(authLogout());
    }
  };
};
