import React, { Component } from "react";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HomeIcon from "@material-ui/icons/Home";
import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const styles = (theme) => ({
  title: {
    flexGrow: 1,
  },
});

class CustomToolbar extends Component {
  state = { anchorEl: null };

  handleMenuClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = () => {
    this.handleMenuClose();
    this.redirect("/auth/logout");
  };

  redirect = (to) => {
    this.props.history.push(to);
  };

  render() {
    if (!this.props.isAuthenticated) return null;
    const { classes } = this.props;

    return (
      <AppBar position="static">
        <Toolbar variant="dense">
          <IconButton color="inherit" onClick={() => this.redirect("/recipes")}>
            <HomeIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {this.props.toolbarTitle}
          </Typography>
          <IconButton
            color="inherit"
            onClick={() => this.redirect("/recipes/new")}
          >
            <AddCircleIcon />
          </IconButton>
          <IconButton color="inherit" onClick={this.handleMenuClick}>
            <AccountCircleIcon />
          </IconButton>
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleMenuClose}
          >
            <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    toolbarTitle: state.page.title,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps)(withRouter(CustomToolbar))
);
