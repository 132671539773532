export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_REFRESH = "AUTH_REFRESH";
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";

export const FETCH_RECIPES_START = "FETCH_RECIPES_START";
export const FETCH_RECIPES_SUCCESS = "FETCH_RECIPES_SUCCESS";
export const FETCH_RECIPES_FAIL = "FETCH_RECIPES_FAIL";

export const RECIPE_EDITOR_ADD_SECTION_ENTRY = "RECIPE_EDITOR_ADD_SECTION_ENTRY";
export const RECIPE_EDITOR_DEL_SECTION_ENTRY = "RECIPE_EDITOR_DEL_SECTION_ENTRY";
export const RECIPE_EDITOR_ENABLE_EDIT = "RECIPE_EDITOR_ENABLE_EDIT";
export const RECIPE_EDITOR_INIT = "RECIPE_EDITOR_INIT";
export const RECIPE_EDITOR_NEW = "RECIPE_EDITOR_NEW";
export const RECIPE_EDITOR_VIEW = "RECIPE_EDITOR_VIEW";
export const RECIPE_EDITOR_EDIT_FIELD = "RECIPE_EDITOR_EDIT_FIELD";

export const PAGE_SET_TITLE = "PAGE_SET_TITLE";