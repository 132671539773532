import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";

const styles = (theme) => ({
  btnGroup: {
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
  btnMargin: {
    marginLeft: theme.spacing(1),
  },
});

class Controls extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.btnGroup}>
        {this.props.readOnly ? (
          <Fab size="small" color="primary" onClick={this.props.edit}>
            <EditIcon />
          </Fab>
        ) : (
          <Fragment>
            <Fab size="small" color="primary" onClick={this.props.save}>
              <SaveIcon />
            </Fab>
            {this.props.existing ? (
              <Fab
                size="small"
                color="secondary"
                className={classes.btnMargin}
                onClick={this.props.delete}
              >
                <DeleteIcon />
              </Fab>
            ) : null}
          </Fragment>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Controls);
