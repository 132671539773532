import React, { Component, Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { autoLogin } from "./store/actions/auth";
import Auth from "./containers/Auth/Auth";
import Layout from "./components/Layout/Layout";
import Logout from "./containers/Auth/Logout/Logout";
import RecipeTable from "./containers/RecipeTable/RecipeTable";
import RecipeCreator from "./components/RecipeCreator/RecipeCreator";
import RecipeViewer from "./components/RecipeViewer/RecipeViewer";

class App extends Component {
  componentDidMount = () => {
    this.props.autoLogin();
  };

  render() {
    return (
      <div>
        <Layout>
          <Switch>
            {this.props.isAuthenticated ? (
              <Fragment>
                <Route path="/auth/logout" exact component={Logout} />
                <Route path="/recipes" exact component={RecipeTable} />
                <Route path="/recipes/new" exact component={RecipeCreator} />
                <Route
                  path="/recipes/edit/:id"
                  exact
                  component={RecipeViewer}
                />
                <Redirect to="/recipes" />
              </Fragment>
            ) : (
              <Fragment>
                <Route path="/auth/login" component={Auth} />
                <Redirect to="/auth/login" />
              </Fragment>
            )}
          </Switch>
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    autoLogin: () => dispatch(autoLogin()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
