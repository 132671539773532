import React from "react";
import { TextField } from "@material-ui/core";

const titleEditor = (props) => (
  <TextField
    label={props.disabled ? null : "Title"}
    value={props.title}
    onChange={props.changed}
    disabled={props.disabled}
    fullWidth
    required
    multiline
  />
);

export default titleEditor;
