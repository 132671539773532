import React, { Fragment } from "react";
import Toolbar from "./Toolbar/Toolbar";

const layout = (props) => {
  return (
    <Fragment>
      <Toolbar />
      <main>{props.children}</main>
    </Fragment>
  );
};

export default layout;
