import React from "react";
import RecipeEditor from "../../containers/RecipeEditor/RecipeEditor";
import classes from "./RecipeCreator.module.css";

const recipeCreator = (props) => (
  <div className={classes.RecipeCreator}>
    <RecipeEditor />
  </div>
);

export default recipeCreator;
