import React from "react";
import { Grid, Typography, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const editorSection = (props) => {
  const { readOnly, title } = props;
  let header = Boolean(title) ? (
    <Grid container item direction="row" spacing={2}>
      <Grid item xs={readOnly ? 12 : 11}>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      {readOnly ? null : (
        <Grid item xs={1}>
          <IconButton onClick={props.addEntry} disabled={readOnly}>
            <AddIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  ) : null;

  return (
    <Grid container item>
      {header}
      {props.children}
    </Grid>
  );
};
export default editorSection;
