import * as actionTypes from "./types";
import { dataApi } from "../../axios";
import { setPageTitle } from "./page";

const recipeEditorInit = () => {
  return { type: actionTypes.RECIPE_EDITOR_INIT };
};

const recipeEditorNew = () => {
  return { type: actionTypes.RECIPE_EDITOR_NEW };
};

export const loadRecipe = (token, userId, recipeId) => {
  return (dispatch) => {
    dispatch(recipeEditorInit());
    if (recipeId) {
      dataApi
        .get(`/recipes/${userId}/${recipeId}.json?auth=${token}`)
        .then((response) => {
          dispatch(recipeEditorView(response.data, recipeId));
          dispatch(setPageTitle("View Recipe"));
        });
    } else {
      dispatch(recipeEditorNew());
      dispatch(setPageTitle("New Recipe"));
    }
  };
};

export const editRecipeField = (section, index, value) => {
  return {
    type: actionTypes.RECIPE_EDITOR_EDIT_FIELD,
    key: section,
    index: index,
    value: value,
  };
};

export const addRecipeSectionEntry = (section) => {
  return {
    type: actionTypes.RECIPE_EDITOR_ADD_SECTION_ENTRY,
    key: section,
  };
};

export const removeRecipeSectionEntry = (section, index) => {
  return {
    type: actionTypes.RECIPE_EDITOR_DEL_SECTION_ENTRY,
    key: section,
    index: index,
  };
};

const enableRecipeEdit = () => {
  return {
    type: actionTypes.RECIPE_EDITOR_ENABLE_EDIT,
  };
};

export const editRecipe = () => {
  return (dispatch) => {
    dispatch(enableRecipeEdit());
    dispatch(setPageTitle("Edit Recipe"));
  };
};

const recipeEditorView = (recipe, recipeId) => {
  return {
    type: actionTypes.RECIPE_EDITOR_VIEW,
    recipe: recipe,
    recipeId: recipeId,
  };
};

export const saveRecipe = (token, userId, recipe, recipeId) => {
  return (dispatch) => {
    (recipeId
      ? dataApi.put(`/recipes/${userId}/${recipeId}.json?auth=${token}`, recipe)
      : dataApi.post(`/recipes/${userId}.json?auth=${token}`, recipe)
    ).then((response) => {
      dispatch(
        recipeEditorView(recipe, recipeId ? recipeId : response.data.name)
      );
      dispatch(setPageTitle("View Recipe"));
    });
  };
};

export const deleteRecipe = (token, userId, recipeId) => {
  return (dispatch) => {
    dataApi
      .delete(`/recipes/${userId}/${recipeId}.json?auth=${token}`)
      .then(() => {
        dispatch(recipeEditorNew());
        dispatch(setPageTitle("New Recipe"));
      });
  };
};
