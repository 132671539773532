import React from "react";
import RecipeEditor from "../../containers/RecipeEditor/RecipeEditor";
import classes from "./RecipeViewer.module.css";

const recipeViewer = (props) => (
  <div className={classes.RecipeViewer}>
    <RecipeEditor initRecipeId={props.match.params.id} />
  </div>
);

export default recipeViewer;
