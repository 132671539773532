import React from "react";
import Grid from "@material-ui/core/Grid";
import StepEditor from "./StepEditor/StepEditor";

const stepsEditor = (props) => {
  const { data, ...rest } = props;
  return (
    <Grid container direction="column" alignItems="stretch" justify="center">
      {data.map((entry, index) => {
        return (
          <Grid item key={index}>
            <StepEditor index={index} value={entry} {...rest} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default stepsEditor;
