import React, { Component } from "react";
import {
  Button,
  CircularProgress,
  CssBaseline,
  Container,
  Grid,
  Link,
  TextField,
} from "@material-ui/core";
import { connect } from "react-redux";
import { auth } from "../../store/actions/auth";
import { Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class Auth extends Component {
  state = {
    isSignup: false,
    formComplete: false,
    email: "",
    password: "",
  };

  emailChangedHandler = (event) => {
    const email = event.target.value;
    this.setState((prevState) => {
      const formComplete = email !== "" && prevState.password !== "";
      return { email: email, formComplete: formComplete };
    });
  };

  passwordChangedHandler = (event) => {
    const password = event.target.value;
    this.setState((prevState) => {
      const formComplete = password !== "" && prevState.email !== "";
      return { password: password, formComplete: formComplete };
    });
  };

  changeFormHandler = () => {
    this.setState((prevState) => {
      return { isSignup: !prevState.isSignup };
    });
  };

  onSubmitHandler = (event) => {
    event.preventDefault();
    this.props.authHandler(
      this.state.email,
      this.state.password,
      this.state.isSignup
    );
  };

  render() {
    if (this.props.loading) return <CircularProgress />;

    const { classes } = this.props;
    const errorMsg = this.props.error ? <span>{this.props.error}</span> : null;
    const redirect = this.props.isAuthenticated ? <Redirect to="/" /> : null;

    return (
      <Container maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {redirect}
          {errorMsg}
          <form
            onSubmit={this.onSubmitHandler}
            className={classes.form}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="email"
              label="Email Address"
              variant="outlined"
              type="text"
              value={this.state.email}
              onChange={this.emailChangedHandler}
              margin="normal"
              fullWidth
              autoFocus
              required
            />
            <TextField
              id="password"
              label="Password"
              variant="outlined"
              type="password"
              value={this.state.password}
              onChange={this.passwordChangedHandler}
              margin="normal"
              fullWidth
              required
            />
            <Button
              color="primary"
              variant="contained"
              disabled={!this.state.formComplete}
              className={classes.submit}
              type="submit"
              fullWidth
            >
              {this.state.isSignup ? "Sign Up" : "Sign In"}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link onClick={this.changeFormHandler} variant="body2">
                  {this.state.isSignup
                    ? "Already got an account? Sign In"
                    : "Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    loading: state.auth.loading,
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToAction = (dispatch) => {
  return {
    authHandler: (email, password, isSignup) =>
      dispatch(auth(email, password, isSignup)),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToAction)(Auth)
);
