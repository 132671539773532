import React, { Component } from "react";
import { connect } from "react-redux";
import Controls from "./Controls/Controls";
import IngredientsEditor from "./IngredientsEditor/IngredientsEditor";
import StepsEditor from "./StepsEditor/StepsEditor";
import TitleEditor from "./TitleEditor/TitleEditor";
import EditorSection from "./EditorSection/EditorSection";
import { CircularProgress, Grid } from "@material-ui/core";
import {
  addRecipeSectionEntry,
  removeRecipeSectionEntry,
  loadRecipe,
  deleteRecipe,
  saveRecipe,
  editRecipe,
  editRecipeField,
} from "../../store/actions/editor";

class RecipeEditor extends Component {
  componentDidMount() {
    const recipeId = this.props.initRecipeId ? this.props.initRecipeId : null;
    this.props.onLoadRecipe(this.props.token, this.props.userId, recipeId);
  }

  onRecipeValueChange = (section, index, event) => {
    this.props.onRecipeValueChange(section, index, event.target.value);
  };

  onAddSectionEntry = (section) => {
    this.props.onAddSectionEntry(section);
  };

  onRemoveSectionEntry = (section, index) => {
    this.props.onRemoveSectionEntry(section, index);
  };

  onEditRecipe = () => {
    this.props.onEditRecipe();
  };

  onSaveRecipe = () => {
    this.props.onSaveRecipe(
      this.props.token,
      this.props.userId,
      this.props.recipe,
      this.props.recipeId
    );
  };

  onDeleteRecipe = () => {
    this.props.onDeleteRecipe(
      this.props.token,
      this.props.userId,
      this.props.recipeId
    );
  };

  render() {
    if (this.props.loading) return <CircularProgress />;
    return (
      <div style={{ marginTop: 25 }}>
        <Grid container spacing={5}>
          <EditorSection readOnly={this.props.readOnly}>
            <TitleEditor
              title={this.props.recipe.title}
              changed={(event) =>
                this.onRecipeValueChange("title", null, event)
              }
              disabled={this.props.readOnly}
            />
          </EditorSection>
          <EditorSection
            title="Ingredients"
            readOnly={this.props.readOnly}
            addEntry={() => this.onAddSectionEntry("ingredients")}
          >
            <IngredientsEditor
              disabled={this.props.readOnly}
              data={this.props.recipe.ingredients}
              changed={this.onRecipeValueChange}
              removed={this.onRemoveSectionEntry}
            />
          </EditorSection>
          <EditorSection
            title="Steps"
            readOnly={this.props.readOnly}
            addEntry={() => this.onAddSectionEntry("steps")}
          >
            <StepsEditor
              data={this.props.recipe.steps}
              changed={this.onRecipeValueChange}
              removed={this.onRemoveSectionEntry}
              disabled={this.props.readOnly}
            />
          </EditorSection>
        </Grid>
        <Controls
          delete={this.onDeleteRecipe}
          edit={this.onEditRecipe}
          save={this.onSaveRecipe}
          readOnly={this.props.readOnly}
          existing={Boolean(this.props.recipeId)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    recipe: state.editor.recipe,
    recipeId: state.editor.recipeId,
    loading: state.editor.loading,
    readOnly: state.editor.readOnly,
    token: state.auth.token,
    userId: state.auth.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddSectionEntry: (section) => dispatch(addRecipeSectionEntry(section)),
    onRemoveSectionEntry: (section, index) =>
      dispatch(removeRecipeSectionEntry(section, index)),
    onLoadRecipe: (token, userId, recipeId) =>
      dispatch(loadRecipe(token, userId, recipeId)),
    onRecipeValueChange: (section, index, value) =>
      dispatch(editRecipeField(section, index, value)),
    onEditRecipe: () => dispatch(editRecipe()),
    onSaveRecipe: (token, userId, recipe, recipeId) =>
      dispatch(saveRecipe(token, userId, recipe, recipeId)),
    onDeleteRecipe: (token, userId, recipeId) =>
      dispatch(deleteRecipe(token, userId, recipeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipeEditor);
