import * as actionTypes from "../actions/types";

const initialState = {
  title: "Cookbook",
};

const setPageTitle = (state, action) => {
  return { ...state, title: action.title };
};

const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PAGE_SET_TITLE:
      return setPageTitle(state, action);
    default:
      return state;
  }
};

export default pageReducer;
