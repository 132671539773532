import React from "react";
import { Grid, TextField, IconButton } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";

const ingredientEditor = (props) => {
  const formFields = [
    {
      label: "Quantity",
      key: "quantity",
      type: "number",
      xs: 3,
    },
    {
      label: "Unit",
      key: "unit",
      type: "text",
      xs: 3,
    },
    {
      label: "Type",
      key: "type",
      type: "text",
      xs: props.disabled ? 6 : 5,
    },
  ];

  return (
    <Grid container item direction="row" spacing={2}>
      {formFields.map((field) => {
        return (
          <Grid item xs={field.xs} key={field.key}>
            <TextField
              helperText={props.disabled ? null : field.label}
              value={props.entry[field.key]}
              type={field.type}
              disabled={props.disabled}
              onChange={(event) =>
                props.changed("ingredients", [props.index, field.key], event)
              }
              fullWidth
            />
          </Grid>
        );
      })}
      {props.disabled ? null : (
        <Grid item xs={1}>
          <IconButton onClick={() => props.removed("ingredients", props.index)}>
            <RemoveIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default ingredientEditor;
