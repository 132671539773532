import * as actionTypes from "../actions/types";

const initialState = {
  loading: false,
  data: [],
};

const fetchRecipesStart = (state, action) => {
  return { ...state, loading: true };
};

const fetchRecipesSuccess = (state, action) => {
  return { ...state, loading: false, data: action.recipes };
};

const fetchRecipesFail = (state, action) => {
  return { ...state, loading: false, data: [] };
};

const recipesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_RECIPES_START:
      return fetchRecipesStart(state, action);
    case actionTypes.FETCH_RECIPES_SUCCESS:
      return fetchRecipesSuccess(state, action);
    case actionTypes.FETCH_RECIPES_FAIL:
      return fetchRecipesFail(state, action);
    default:
      return state;
  }
};

export default recipesReducer;
